.landing-message {
    height: 450px;
    max-width: 2000px;
    width: 90%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0px auto;
    align-items: center;
    text-align: center;
    border: none;
    border-bottom: 2px solid #3d3d3d;
}

.card {
    color: whitesmoke;
    display: flex;
    flex-wrap: wrap;
    margin: 100px auto;
}

.card-image {
    width: 500px;
    height: 325px;
    margin: 0px auto;
    border-radius: 5px;
    background-color: #1A1D35;
}

.card-content {
    width: 500px;
    padding-left: 15px;
    text-align: left;
    letter-spacing: .5px;
    line-height: 25px;
    font-size: 16px;
    font-weight: 200;
    margin: 0px auto;
}

.tech-stack {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    background-color: #1A1D35;
}

.tech-stack h5 {
    margin-top: 10px;
}

.stack-card {
    width: 250px;
    margin: 0px auto;
    margin-bottom: 25px;
}

.edu-stack {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.edu-card {
    width: 49%;
    margin: 0px auto;
}

.about {
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid #3d3d3d;
    width: 90%;
}

.profile-pic {
    margin: 125px auto 50px auto;
    width: 300px;
    max-height: 428px;
    padding: 0;
    border: none;
    text-align: center;
    border-radius: 10px;
    background-color: #1A1D35;
    box-shadow: 10px -15px 50px rgba(0, 23, 35);
}

.profile-content {
    text-align: center;
    width: 800px;
    margin: 0px auto;
}

.services {
    margin: 0px auto;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #3d3d3d;
    width: 90%;
}

.get-in-touch {
    margin: 0px auto;
    align-items: center;
    text-align: center;
    width: 90%;
}

p {
    color: whitesmoke;
    margin: 4px auto;
}

h2 {
    font-size: 20px;
    color: whitesmoke;
    margin: 2px auto;
}

h3 {
    font-size: 18px;
    font-weight: 500;
    color: #9D00FF;
}

h5 {
    font-size: 18px;
    font-weight: 700;
    color: whitesmoke;
    margin-top: 30px;
    width: 100%;
}

form {
    max-width: 475px;
    margin: 25px auto;
    text-align: left;
    padding: 25px 0px;
}

label {
    margin: 10px;
    font-weight: 500;
}

.form-zone {
    margin: 25px auto;
    margin-top: 50px;
    width: 90%;
}

input[type=text],
[type=email],
select {
    display: inline-block;
    font-size: 15px;
    padding-left: 10px;
    margin: 8px;
    height: 40px;
    width: 91.5%;
    border:none;
    font-family: 'Merriweather', serif;
    border-radius: 4px;
    background-color: #d3d3d3;
}

textarea {
    border:none;
    width: 90%;
    height: 250px;
    padding: 10px;
    font-size: 15px;
    font-family: 'Merriweather', serif;
    margin: 8px;
    border-radius: 4px;
    background-color: #d3d3d3;
}

input[type=submit] {
    font-family: 'Merriweather', serif;
    width: 125px;
    height: 40px;
    background-color: #9D00FF;
    color: #ebebeb;
    font-size: 15px;
    font-weight: 600;
    padding: 12px 20px;
    margin: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s all;
}

@media screen and (max-width: 1550px) {}

@media screen and (max-width: 1000px) {
    .stack-card {
        width: 100%;
    }

    .edu-card {
        width: 100%;
    }
    .profile-pic {
        margin: 15px auto;
    }
}

@media screen and (max-width: 500px) {}