
*{
  scroll-behavior: smooth;
}
  body {
  margin: 0;
  background-image: url(./Assets/PageBkg.png);
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}