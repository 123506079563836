.navigation {
    display: flex;
    width: 100%;
    height: 55px;
    border-bottom: 2px solid grey;
    text-decoration: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.nav-title{
    height: 53px;
    width: 250px;
    display: flex;
    margin-left: 5%;
}
.logo{
    width: 70px;
    object-fit: contain;
    margin-top: 2px;
    margin-left: 15%;
}

.nav-list {
    margin-right: 5%;
    display: flex;
    margin-left: auto;
    align-items: center;
}

.nav-list ul {
    display: flex;
    flex-wrap: wrap;
    z-index: 2;
}

.nav-list li {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    color: #f5f5f5;
    width: 100px;
    height: 53px;
    text-align: center;
}
.nav-icon{
    width:100%;
    font-size: 20px;
    font-weight: 100;
    margin: 7px auto 0px auto;
    color: whitesmoke;
}
.nav-list li a {
    text-decoration: none;
    color: #f5f5f5;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    transition: 0.1s all;
}
.nav-list li:hover {
    opacity: 0.33;
    border-bottom: 4px solid #f5f5f5;
    border-radius: 3px;
}
.hamburger {
    border: 0;
    height: 36px;
    width: 36px;
    padding: .125rem;
    position: absolute;
    top: 6px;
    right: 5%;
    color: white;
    cursor: pointer;
    display: none;
}
.hamburger:hover {
    cursor: pointer;
    color: rgba(1, 101, 225);
    transition: 0.4s;
}
.close-menu {
    position: absolute;
    top: 12px;
    right: 6%;
    border: 0;
    height: 32px;
    width: 32px;
    color: whitesmoke;
    cursor: pointer;
    display: none;
}

.close-menu:hover {
    cursor: pointer;
    color: rgba(1, 101, 225);
    transform: rotate(-90deg);
    transition: 0.5s;
}
@media screen and (max-width: 999px) {
    .hamburger {
        display: block;
    }
    .nav-list ul {
        position: absolute;
        top: 41px;
        right: 0px;
        display: none;
        flex-direction: column;
        width: 45%;
        height: calc(100% - 50px);
        background-color: rgb(0, 0, 0, 0.93);
        border-radius: 3px;
        text-transform: uppercase;
        transition: 0.4s ease-in;
    }
    .nav-icon{
        width:20%;
        font-size: 20px;
        font-weight: 100;
        margin: 5px -5px 0px 0px;
        color: whitesmoke;
    }
    .nav-list li {
        margin: 0px;
        height: 65px;
        width: 100%;
        border-bottom: 1px solid rgba(245, 245, 245, 0.7);
    }

    .nav-list li a {
        font-size: 16px;
        letter-spacing: 1px;
        text-align: left;
        width: 100%;
        padding-top:15px;
        height: 50px;
        margin: 0px;
    }

    .contact-link {
        display: block;
    }
    .nav-list.expanded ul {
        display: block;
    }

    .nav-list.expanded .close-menu {
        display: block;
    }

    .nav-list.expanded .hamburger {
        display: none;
    }

}

@media screen and (max-width: 450px) {
    .nav-list ul {
        width: 100%;
    }
    .nav-title{
        margin-left: 0px;
    }
}
